@import '../../colors.scss';

.navbar {
  background-color: $navbarColor;
  height: 40px;
  width: 100%;
  display: flex;
  position: fixed;

  .navbar-icon {
    width: 120px;
    margin: 5px;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;

    &:hover {
      background-color: $mainButtonHover;
    }
  }
  .navbar-buttons {
    width: calc(100% - 310px);
    overflow: hidden;
    .fa {
      display: none;
      font-size: 30px;
      margin: 5px;
    }
    @media screen and (max-width: 810px) {
      .button {
        display: none;
      }
      .fa {
        display: inline-block;
      }
      &.responsive {
        position: relative;
        height: fit-content;
        background-color: $navbarColor;
        div {
          float: none;
          display: block;
          text-align: left;
        }
      }
    }
  }

  .navbar-profile {
    height: 24px;
    width: 150px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 2px;
    text-align: center;
  }
}
